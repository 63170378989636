import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/FadeIn"

import Mar from "../images/mar.png"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="row">
        <div className="col-sm-12">
          <h1>Balans in hoofd en huis</h1>
          <h3>
            Professional organizer en sociaal psychiatrische verpleegkundige
          </h3>
          <p>
            Structuur aanbrengen, keuzes maken en overzicht aanbrengen. Er zijn
            allerlei oorzaken mogelijk waardoor het niet altijd gemakkelijk is
            om dit voor elkaar te krijgen of bereikte resultaten vast te houden.
            Lastige privéomstandigheden, al dan niet gecombineerd met een hoge
            werkdruk, kunnen er toe leiden dat u het gevoel hebt dat u vast zit
            en niet toe komt aan wat u zelf echt wilt.
          </p>
        </div>

        <div className="col">
          <FadeIn src={Mar} alt="Marjolein Knoppers" />

            <ul>
              <li>
                Bent u op zoek naar professionele begeleiding/coaching om de
                ‘grip’ weer op uw leven te krijgen?
              </li>
              <li>
                Zoekt u iemand die niet alleen vanachter het bureau zegt hoe u uw
                leven op de rit kan krijgen, maar ook iemand die u daarin
                praktisch begeleidt en coacht?
              </li>
            </ul>

            <p>
              Dan biedt Balans in hoofd en huis uitkomst. Omdat er raakvlakken
              zijn tussen chaos in je hoofd en chaos in je huis, heeft Balans in
              hoofd en huis zich geschoold in twee beroepen:
            </p>

            <ul>
              <li>Sociaal Psychiatrisch Verpleegkundige</li>
              <li>Professional Organizer</li>
            </ul>

          <p>
            Naast de gespecialiseerde hulp, begeleiding of behandeling op
            psychisch vlak, is er tevens aandacht voor praktische hulp en
            advisering in het creëren van orde bij onoverzichtelijke situaties.
          </p>

          <p>Het werkgebied is regio Gelderland.</p>
        </div>
      </div>

      <Link
        to="/particulieren"
        className="btn btn-outline-danger mr-3 mt-4 mb-2"
        role="button"
      >
        Meer informatie
      </Link>
      <Link to="/contact" className="btn btn-danger mt-4 mb-2" role="button">
        Neem contact op
      </Link>
    </Layout>
  )
}
export default IndexPage
